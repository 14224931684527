import { useState } from "react";
import Box from "@mui/material/Box";
import Grid from '@mui/material/Unstable_Grid2';
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import Button from "@mui/material/Button";

export default function editStars({ stars, resilience, neuro, processing, handleUpdate, handleCancel, auth, setSnackbar }) {
  const [precisionShapingConvergence, setPrecisionShapingConvergence] = useState(stars.precisionShaping.convergence);
  const [precisionShapingDivergence, setPrecisionShapingDivergence] = useState(stars.precisionShaping.divergence);
  const [precisionRefiningJumpRandom, setPrecisionRefiningJumpRandom] = useState(stars.precisionRefining.jumpRandom);
  const [precisionRefiningJumpDuctions, setPrecisionRefiningJumpDuctions] = useState(stars.precisionRefining.jumpDuctions);
  const [precisionSharpeningPursuits, setPrecisionSharpeningPursuits] = useState(stars.precisionSharpening.pursuits);
  const [precisionSharpeningSaccades, setPrecisionSharpeningSaccades] = useState(stars.precisionSharpening.saccades);
  const [powerShapingSeated, setPowerShapingSeated] = useState(stars.powerShaping.seated);
  const [powerShapingStanding, setPowerShapingStanding] = useState(stars.powerShaping.standing);
  const [powerRefiningSeated, setPowerRefiningSeated] = useState(stars.powerRefining.seated);
  const [powerRefiningStanding, setPowerRefiningStanding] = useState(stars.powerRefining.standing);
  const [powerSharpeningSeated, setPowerSharpeningSeated] = useState(stars.powerSharpening.seated);
  const [powerSharpeningStanding, setPowerSharpeningStanding] = useState(stars.powerSharpening.standing);
  const [focusShapingSeated, setFocusShapingSeated] = useState(stars.focusShaping.seated);
  const [focusShapingStanding, setFocusShapingStanding] = useState(stars.focusShaping.standing);
  const [focusRefiningSeated, setFocusRefiningSeated] = useState(stars.focusRefining.seated);
  const [focusRefiningStanding, setFocusRefiningStanding] = useState(stars.focusRefining.standing);
  const [focusSharpeningSeated, setFocusSharpeningSeated] = useState(stars.focusSharpening.seated);
  const [focusSharpeningStanding, setFocusSharpeningStanding] = useState(stars.focusSharpening.standing);
  const [neuroShapingCz, setNeuroShapingCz] = useState(stars.CzShaping?.count || 0);
  const [neuroShapingT3, setNeuroShapingT3] = useState(stars.T3Shaping?.count || 0);
  const [neuroShapingT4, setNeuroShapingT4] = useState(stars.T4Shaping?.count || 0);
  const [neuroRefiningCz, setNeuroRefiningCz] = useState(stars.CzRefining?.count || 0);
  const [neuroRefiningT3, setNeuroRefiningT3] = useState(stars.T3Refining?.count || 0);
  const [neuroRefiningT4, setNeuroRefiningT4] = useState(stars.T4Refining?.count || 0);
  const [neuroSharpeningCz, setNeuroSharpeningCz] = useState(stars.CzSharpening?.count || 0);
  const [neuroSharpeningT3, setNeuroSharpeningT3] = useState(stars.T3Sharpening?.count || 0);
  const [neuroSharpeningT4, setNeuroSharpeningT4] = useState(stars.T4Sharpening?.count || 0);

  function validate() {
    handleUpdate({
      precisionShaping: {
        convergence: precisionShapingConvergence,
        divergence: precisionShapingDivergence,
      },
      precisionRefining: {
        jumpRandom: precisionRefiningJumpRandom,
        jumpDuctions: precisionRefiningJumpDuctions,
      },
      precisionSharpening: {
        pursuits: precisionSharpeningPursuits,
        saccades: precisionSharpeningSaccades,
      },
      powerShaping: {
        seated: powerShapingSeated,
        standing: powerShapingStanding,
      },
      powerRefining: {
        seated: powerRefiningSeated,
        standing: powerRefiningStanding,
      },
      powerSharpening: {
        seated: powerSharpeningSeated,
        standing: powerSharpeningStanding,
      },
      focusShaping: {
        seated: focusShapingSeated,
        standing: focusShapingStanding,
      },
      focusRefining: {
        seated: focusRefiningSeated,
        standing: focusRefiningStanding,
      },
      focusSharpening: {
        seated: focusSharpeningSeated,
        standing: focusSharpeningStanding,
      },
      CzShaping: {
        count: neuroShapingCz,
      },
      T3Shaping: {
        count: neuroShapingT3,
      },
      T4Shaping: {
        count: neuroShapingT4,
      },
      CzRefining: {
        count: neuroRefiningCz,
      },
      T3Refining: {
        count: neuroRefiningT3,
      },
      T4Refining: {
        count: neuroRefiningT4,
      },
      CzSharpening: {
        count: neuroSharpeningCz,
      },
      T3Sharpening: {
        count: neuroSharpeningT3,
      },
      T4Sharpening: {
        count: neuroSharpeningT4,
      },
    });
  }

  function resetForm() {
    setPrecisionShapingConvergence(stars.precisionShaping.convergence);
    setPrecisionShapingDivergence(stars.precisionShaping.divergence);
    setPrecisionRefiningJumpRandom(stars.precisionRefining.jumpRandom);
    setPrecisionRefiningJumpDuctions(stars.precisionRefining.jumpDuctions);
    setPrecisionSharpeningPursuits(stars.precisionSharpening.pursuits);
    setPrecisionSharpeningSaccades(stars.precisionSharpening.saccades);
    setPowerShapingSeated(stars.powerShaping.seated);
    setPowerShapingStanding(stars.powerShaping.standing);
    setPowerSharpeningSeated(stars.powerSharpening.seated);
    setPowerSharpeningStanding(stars.powerSharpening.standing);
    setPowerRefiningSeated(stars.powerRefining.seated);
    setPowerRefiningStanding(stars.powerRefining.standing);
    setFocusShapingSeated(stars.focusShaping.seated);
    setFocusShapingStanding(stars.focusShaping.standing);
    setFocusSharpeningSeated(stars.focusSharpening.seated);
    setFocusSharpeningStanding(stars.focusSharpening.standing);
    setFocusRefiningSeated(stars.focusRefining.seated);
    setFocusRefiningStanding(stars.focusRefining.standing);
    setNeuroShapingCz(stars.CzShaping?.count || 0);
    setNeuroShapingT3(stars.T3Shaping?.count || 0);
    setNeuroShapingT4(stars.T4Shaping?.count || 0);
    setNeuroRefiningCz(stars.CzRefining?.count || 0);
    setNeuroRefiningT3(stars.T3Refining?.count || 0);
    setNeuroRefiningT4(stars.T4Refining?.count || 0);
    setNeuroSharpeningCz(stars.CzSharpening?.count || 0);
    setNeuroSharpeningT3(stars.T3Sharpening?.count || 0);
    setNeuroSharpeningT4(stars.T4Sharpening?.count || 0);
    if (handleCancel) {
      handleCancel();
    }
  }

  return (
    <Box component="form" noValidate>
      <Grid container spacing={2}>
        { resilience && (
          <>
            <Grid xs={6} sm={6} lg={3}>
              <FormControl
                fullWidth
                margin="normal"
                variant="outlined"
              >
                <InputLabel htmlFor="outlined-precision-shaping-convergence">
                  Precision Shaping - Convergence
                </InputLabel>
                <OutlinedInput
                  id="outlined-precision-shaping-convergence"
                  type="number"
                  value={precisionShapingConvergence}
                  label="Precision Shaping - Convergence"
                  onChange={event => {
                    setPrecisionShapingConvergence(event.target.value);
                  }}
                />
              </FormControl>
              <FormControl
                fullWidth
                margin="normal"
                variant="outlined"
              >
                <InputLabel htmlFor="outlined-precision-shaping-divergence">
                  Precision Shaping - Divergence
                </InputLabel>
                <OutlinedInput
                  id="outlined-precision-shaping-divergence"
                  type="number"
                  value={precisionShapingDivergence}
                  label="Precision Shaping - Divergence"
                  onChange={event => {
                    setPrecisionShapingDivergence(event.target.value);
                  }}
                />
              </FormControl>
              <FormControl
                fullWidth
                margin="normal"
                variant="outlined"
              >
                <InputLabel htmlFor="outlined-precision-refining-jumpRandom">
                  Precision Refining - Jump Random
                </InputLabel>
                <OutlinedInput
                  id="outlined-precision-shaping-jumpRandom"
                  type="number"
                  value={precisionRefiningJumpRandom}
                  label="Precision Refining - Jump Random"
                  onChange={event => {
                    setPrecisionRefiningJumpRandom(event.target.value);
                  }}
                />
              </FormControl>
              <FormControl
                fullWidth
                margin="normal"
                variant="outlined"
              >
                <InputLabel htmlFor="outlined-precision-refining-jumpDuctions">
                  Precision Refining - Jump Ductions
                </InputLabel>
                <OutlinedInput
                  id="outlined-precision-refining-jumpDuctions"
                  type="number"
                  value={precisionRefiningJumpDuctions}
                  label="Precision Refining - Jump Ductions"
                  onChange={event => {
                    setPrecisionRefiningJumpDuctions(event.target.value);
                  }}
                />
              </FormControl>
              <FormControl
                fullWidth
                margin="normal"
                variant="outlined"
              >
                <InputLabel htmlFor="outlined-precision-sharpening-pursuits">
                  Precision Sharpening - Pursuits
                </InputLabel>
                <OutlinedInput
                  id="outlined-precision-sharpening-pursuits"
                  type="number"
                  value={precisionSharpeningPursuits}
                  label="Precision Sharpening - Pursuits"
                  onChange={event => {
                    setPrecisionSharpeningPursuits(event.target.value);
                  }}
                />
              </FormControl>
              <FormControl
                fullWidth
                margin="normal"
                variant="outlined"
              >
                <InputLabel htmlFor="outlined-precision-sharpening-saccades">
                  Precision Sharpening - Saccades
                </InputLabel>
                <OutlinedInput
                  id="outlined-precision-sharpening-saccades"
                  type="number"
                  value={precisionSharpeningSaccades}
                  label="Precision Sharpening - Saccades"
                  onChange={event => {
                    setPrecisionSharpeningSaccades(event.target.value);
                  }}
                />
              </FormControl>
            </Grid>
            <Grid xs={6} sm={6} lg={3}>
              <FormControl
                fullWidth
                margin="normal"
                variant="outlined"
              >
                <InputLabel htmlFor="outlined-power-shaping-seated">
                  Power Shaping - Seated
                </InputLabel>
                <OutlinedInput
                  id="outlined-power-shaping-seated"
                  type="number"
                  value={powerShapingSeated}
                  label="Power Shaping - Seated"
                  onChange={event => {
                    setPowerShapingSeated(event.target.value);
                  }}
                />
              </FormControl>
              <FormControl
                fullWidth
                margin="normal"
                variant="outlined"
              >
                <InputLabel htmlFor="outlined-power-shaping-stainding">
                  Power Shaping - Standing
                </InputLabel>
                <OutlinedInput
                  id="outlined-power-shaping-standing"
                  type="number"
                  value={powerShapingStanding}
                  label="Power Shaping - Standing"
                  onChange={event => {
                    setPowerShapingStanding(event.target.value);
                  }}
                />
              </FormControl>
              <FormControl
                fullWidth
                margin="normal"
                variant="outlined"
              >
                <InputLabel htmlFor="outlined-power-refining-seated">
                  Power Refining - Seated
                </InputLabel>
                <OutlinedInput
                  id="outlined-power-shaping-seated"
                  type="number"
                  value={powerRefiningSeated}
                  label="Power Refining - Seated"
                  onChange={event => {
                    setPowerRefiningSeated(event.target.value);
                  }}
                />
              </FormControl>
              <FormControl
                fullWidth
                margin="normal"
                variant="outlined"
              >
                <InputLabel htmlFor="outlined-power-refining-stainding">
                  Power Refining - Standing
                </InputLabel>
                <OutlinedInput
                  id="outlined-power-refining-standing"
                  type="number"
                  value={powerRefiningStanding}
                  label="Power Refining - Standing"
                  onChange={event => {
                    setPowerRefiningStanding(event.target.value);
                  }}
                />
              </FormControl>
              <FormControl
                fullWidth
                margin="normal"
                variant="outlined"
              >
                <InputLabel htmlFor="outlined-power-sharpening-seated">
                  Power Sharpening - Seated
                </InputLabel>
                <OutlinedInput
                  id="outlined-power-sharpening-seated"
                  type="number"
                  value={powerSharpeningSeated}
                  label="Power Sharpening - Seated"
                  onChange={event => {
                    setPowerSharpeningSeated(event.target.value);
                  }}
                />
              </FormControl>
              <FormControl
                fullWidth
                margin="normal"
                variant="outlined"
              >
                <InputLabel htmlFor="outlined-power-sharpening-stainding">
                  Power Sharpening - Standing
                </InputLabel>
                <OutlinedInput
                  id="outlined-power-sharpening-standing"
                  type="number"
                  value={powerSharpeningStanding}
                  label="Power Sharpening - Standing"
                  onChange={event => {
                    setPowerSharpeningStanding(event.target.value);
                  }}
                />
              </FormControl>
            </Grid>
            <Grid xs={6} sm={6} lg={3}>
              <FormControl
                fullWidth
                margin="normal"
                variant="outlined"
              >
                <InputLabel htmlFor="outlined-focus-shaping-seated">
                  Focus Shaping - Seated
                </InputLabel>
                <OutlinedInput
                  id="outlined-focus-shaping-seated"
                  type="number"
                  value={focusShapingSeated}
                  label="Focus Shaping - Seated"
                  onChange={event => {
                    setFocusShapingSeated(event.target.value);
                  }}
                />
              </FormControl>
              <FormControl
                fullWidth
                margin="normal"
                variant="outlined"
              >
                <InputLabel htmlFor="outlined-focus-shaping-stainding">
                  Focus Shaping - Standing
                </InputLabel>
                <OutlinedInput
                  id="outlined-focus-shaping-standing"
                  type="number"
                  value={focusShapingStanding}
                  label="Focus Shaping - Standing"
                  onChange={event => {
                    setFocusShapingStanding(event.target.value);
                  }}
                />
              </FormControl>
              <FormControl
                fullWidth
                margin="normal"
                variant="outlined"
              >
                <InputLabel htmlFor="outlined-focus-refining-seated">
                  Focus Refining - Seated
                </InputLabel>
                <OutlinedInput
                  id="outlined-focus-shaping-seated"
                  type="number"
                  value={focusRefiningSeated}
                  label="Focus Refining - Seated"
                  onChange={event => {
                    setFocusRefiningSeated(event.target.value);
                  }}
                />
              </FormControl>
              <FormControl
                fullWidth
                margin="normal"
                variant="outlined"
              >
                <InputLabel htmlFor="outlined-focus-refining-stainding">
                  Focus Refining - Standing
                </InputLabel>
                <OutlinedInput
                  id="outlined-focus-refining-standing"
                  type="number"
                  value={focusRefiningStanding}
                  label="Focus Refining - Standing"
                  onChange={event => {
                    setFocusRefiningStanding(event.target.value);
                  }}
                />
              </FormControl>
              <FormControl
                fullWidth
                margin="normal"
                variant="outlined"
              >
                <InputLabel htmlFor="outlined-focus-sharpening-seated">
                  Focus Sharpening - Seated
                </InputLabel>
                <OutlinedInput
                  id="outlined-focus-sharpening-seated"
                  type="number"
                  value={focusSharpeningSeated}
                  label="Focus Sharpening - Seated"
                  onChange={event => {
                    setFocusSharpeningSeated(event.target.value);
                  }}
                />
              </FormControl>
              <FormControl
                fullWidth
                margin="normal"
                variant="outlined"
              >
                <InputLabel htmlFor="outlined-focus-sharpening-stainding">
                  Focus Sharpening - Standing
                </InputLabel>
                <OutlinedInput
                  id="outlined-focus-sharpening-standing"
                  type="number"
                  value={focusSharpeningStanding}
                  label="Focus Sharpening - Standing"
                  onChange={event => {
                    setFocusSharpeningStanding(event.target.value);
                  }}
                />
              </FormControl>
            </Grid>
          </>
        )}
        { neuro && (
          <Grid xs={6} sm={6} lg={3}>
            <FormControl
              fullWidth
              margin="normal"
              variant="outlined"
            >
              <InputLabel htmlFor="outlined-cz-shaping">
                Neuro Shaping - Cz
              </InputLabel>
              <OutlinedInput
                id="outlined-cz-shaping"
                type="number"
                value={neuroShapingCz}
                label="Neuro Shaping - Cz"
                onChange={event => {
                  setNeuroShapingCz(event.target.value);
                }}
              />
            </FormControl>
            <FormControl
              fullWidth
              margin="normal"
              variant="outlined"
            >
              <InputLabel htmlFor="outlined-t3-shaping">
                Neuro Shaping - T3
              </InputLabel>
              <OutlinedInput
                id="outlined-t3-shaping"
                type="number"
                value={neuroShapingT3}
                label="Neuro Shaping - T3"
                onChange={event => {
                  setNeuroShapingT3(event.target.value);
                }}
              />
            </FormControl>
            <FormControl
              fullWidth
              margin="normal"
              variant="outlined"
            >
              <InputLabel htmlFor="outlined-t4-shaping">
                Neuro Shaping - T4
              </InputLabel>
              <OutlinedInput
                id="outlined-t4-shaping"
                type="number"
                value={neuroShapingT4}
                label="Neuro Shaping - T4"
                onChange={event => {
                  setNeuroShapingT4(event.target.value);
                }}
              />
            </FormControl>
            <FormControl
              fullWidth
              margin="normal"
              variant="outlined"
            >
              <InputLabel htmlFor="outlined-cz-refining">
                Neuro Refining - Cz
              </InputLabel>
              <OutlinedInput
                id="outlined-cz-refining"
                type="number"
                value={neuroRefiningCz}
                label="Neruo Refining - Cz"
                onChange={event => {
                  setNeuroRefiningCz(event.target.value);
                }}
              />
            </FormControl>
            <FormControl
              fullWidth
              margin="normal"
              variant="outlined"
            >
              <InputLabel htmlFor="outlined-t3-refining">
                Neuro Refining - T3
              </InputLabel>
              <OutlinedInput
                id="outlined-t3-refining"
                type="number"
                value={neuroRefiningT3}
                label="Neuro Refining - T3"
                onChange={event => {
                  setNeuroRefiningT3(event.target.value);
                }}
              />
            </FormControl>
            <FormControl
              fullWidth
              margin="normal"
              variant="outlined"
            >
              <InputLabel htmlFor="outlined-t4-refining">
                Neuro Refining - T4
              </InputLabel>
              <OutlinedInput
                id="outlined-t4-refining"
                type="number"
                value={neuroRefiningT4}
                label="Neuro Refining - T4"
                onChange={event => {
                  setNeuroRefiningT4(event.target.value);
                }}
              />
            </FormControl>
            <FormControl
              fullWidth
              margin="normal"
              variant="outlined"
            >
              <InputLabel htmlFor="outlined-cz-sharpening">
                Neuro Sharpening - Cz
              </InputLabel>
              <OutlinedInput
                id="outlined-cz-sharpening"
                type="number"
                value={neuroSharpeningCz}
                label="Neuro Sharpening - Cz"
                onChange={event => {
                  setNeuroSharpeningCz(event.target.value);
                }}
              />
            </FormControl>
            <FormControl
              fullWidth
              margin="normal"
              variant="outlined"
            >
              <InputLabel htmlFor="outlined-t3-sharpening">
                Neuro Sharpening - T3
              </InputLabel>
              <OutlinedInput
                id="outlined-t3-sharpening"
                type="number"
                value={neuroSharpeningT3}
                label="Neuro Sharpening - T3"
                onChange={event => {
                  setNeuroSharpeningT3(event.target.value);
                }}
              />
            </FormControl>
            <FormControl
              fullWidth
              margin="normal"
              variant="outlined"
            >
              <InputLabel htmlFor="outlined-t4-sharpening">
                Neuro Sharpening - T4
              </InputLabel>
              <OutlinedInput
                id="outlined-t4-sharpening"
                type="number"
                value={neuroSharpeningT4}
                label="Neuro Sharpening - T4"
                onChange={event => {
                  setNeuroSharpeningT4(event.target.value);
                }}
              />
            </FormControl>
          </Grid>
        )}
      </Grid>
      <Box
        display="flex"
        justifyContent="flex-end"
        alignItems="flex-end"
      >
        <Button
          disabled={processing || null}
          variant="outlined"
          color="error"
          onClick={resetForm}
        >
          Cancel
        </Button>
        <Button
          disabled={processing || null}
          sx={{ml: 1}}
          variant="contained"
          onClick={validate}
        >
          Save
        </Button>
      </Box>
    </Box>
  )
}
